import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core'
import {
    LuxonDateAdapter,
    MAT_LUXON_DATE_ADAPTER_OPTIONS,
    MAT_LUXON_DATE_FORMATS
} from '@angular/material-luxon-adapter'
import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE
} from '@angular/material/core'
import {MatPaginatorIntl} from '@angular/material/paginator'
import {MatSnackBarModule} from '@angular/material/snack-bar'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import '@angular/common/locales/global/fr'
import {SpbPaginatorSv, ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {SpbCommonModule} from './common/common.module'
import {ConfigService} from './services/config.service'

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatSnackBarModule,
        ThemeModule,
        SpbCommonModule], providers: [
        WINDOW_PROVIDERS,
        ConfigService,
        {
            // This is nice magic to make sure we load any existing tokens.
            // We return an observable, the "angulars" will subscribe to this
            // and when it completes, it will continue.
            provide: APP_INITIALIZER,
            useFactory: (s: ConfigService) => () => s.bootstrap(),
            deps: [ConfigService],
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'fr' },
        { provide: MAT_DATE_LOCALE, useValue: 'sv-SE' },
        { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_LUXON_DATE_FORMATS },
        { provide: MatPaginatorIntl, useClass: SpbPaginatorSv },
        { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true, firstDayOfWeek: 1 } },
        provideHttpClient(withInterceptors([authInterceptor, responseInterceptor])),
    ] })
export class AppModule {
}
